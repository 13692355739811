import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
import 'animate.css'
Vue.use(Element)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
Vue.prototype.$wow = wow
