import request from "@/utils/request";

export function defaultSystemConfig() {
  return request.get('/systemConfig')
}

export function webNavs() {
  return request.get('/webNavs')
}

// 首页数据
export function indexData() {
  return request.get('/indexData')
}

// 联系我们表单
export function contactUs(data) {
  return request.post('/feedback', data)
}

// 行业资讯列表
export function newsPage(params) {
  return request.get(`/newsArticleList?page_size=${params.page_size}&page=${params.page}`)
}

// 行业资讯列表详情
export function articleInfo(id, params = {}) {
  return request.get(`/articleInfo/${id}`, {params})
}

export function casesPage(params) {
  return request.get('/casesArticleList', {params})
}

// 关于我们图片
export function abortImageList() {
  return request.get('/abortImageList')
}