import axios from 'axios'
// import store from '@/store'
import {Message} from 'element-ui'
// import router from "@/router"

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
}

const service = axios.create({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
})

service.interceptors.request.use(
  config => {
    // let authorization = store.state.auth.authorization
    // if (authorization && !config.headers['X-Remove-Authorization'] && config.headers.Authorization == undefined) {
    //   config.headers['Authorization'] = 'Bearer ' + authorization
    // }
    return config
  },
  error => {
    return Promise.reject(error.response.data)
  }
)

service.interceptors.response.use(
  response => {
    // let token = response.headers.authorization
    // if (token) {
    //   store.dispatch('auth/refreshToken', token.substr(7))
    // }
    // eslint-disable-next-line no-prototype-builtins
    if (response.headers.hasOwnProperty('content-type') && response.headers['content-type'].toLowerCase().includes('application/json')) {
      if (response.data instanceof Blob) {
        if (response.data.type == 'application/json') {
          return new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsText(response.data, 'utf-8')
            reader.onload = () => {
              let data = JSON.parse(reader.result)
              if (data.status == 200) {
                return resolve(data)
              }
              let message = data.message
              Message.error(message)
              return reject(data)
            }
            reader.onerror = () => {
              return reject(reader.error)
            }
          })
        } else {
          return response.data
        }
      }
      if (response.data.status == 200) {
        return response.data.data
      }
      let message = response.data.message
      let errors = []
      // eslint-disable-next-line no-prototype-builtins
      if (response.data.data.hasOwnProperty('errors') && response.data.data.errors) {
        for (let key in response.data.data.errors) {
          errors.push(...response.data.data.errors[key])
        }
      }
      if (errors) {
        Message.error(message + '；' + errors.join('、'))
      } else {
        Message.error(message)
      }
      return Promise.reject(response.data)
    }
    //body
    return response.data
  },
  error => {
    console.log('http错误',error.response.status)
    Message.error(error.response && error.response.data.message || codeMessage[error.response.status] || '您的网络发生异常，无法连接服务器')
    return Promise.reject(error.response.data)
  }
)

export default service
