<template>
  <div>
    <div class="bg-[#232323] clear-both relative z-100" v-if="config">
      <!--pc-->
      <div class="container mx-auto lg:h-[310px] lg:py-[50px] py-5 px-2.5 md:block hidden">
        <div class="flex justify-between items-start">
          <div class="mb-4 md:m-0 border-r border-[#3A3D43] pr-20">
            <div class="flex items-center" v-show="config.ContactPhone">
              <img src="@/assets/hot_call.png" alt="" class="h-[36px] w-[36px]">
              <div class="text-[#cccccc]  pl-5">
                <div class="text-sm">联系电话</div>
                <div class="font-bold text-lg pt-2.5">{{ config.ContactPhone.split(',')[0] }}</div>
              </div>
            </div>
            <div class="flex items-center mt-10" v-show="config.HighTechNumber !== 'null'">
              <img src="@/assets/gaoxinjishu.png" alt="" class="h-[36px] w-[36px]">
              <div class="text-[#cccccc]  pl-5">
                <div class="text-sm">国家高新技术企业编号</div>
                <div class="font-bold text-lg pt-2.5">{{ config.HighTechNumber }}</div>
              </div>
            </div>
          </div>
          <div class="flex-1 flex justify-between items-start pl-20">
            <div class="h-full flex-1 md:text-left text-center" v-for="(item, footerIndex) in navs" :key="footerIndex">
              <div class="text-white leading-6.5 text-lg font-medium"
                   @click="toPage(item)">{{ item.name }}
              </div>
              <div class="text-hover cursor-pointer text-[#cccccc] text-base pt-4 leading-6"
                   v-for="(child,index) in item.children" :key="index" @click="toPage(child)">
                {{ child.name }}
              </div>
            </div>
            <div class="mb-4 md:m-0 text-center" v-show="config.ContactQrcode">
              <img :src="config.ContactQrcode" alt="" class="inline lg:w-[146px] lg:h-[146px]  w-[100px] h-[100px]">
              <div class="text-[#cccccc] text-center lg:w-[160px] w-[100px] text-base font-medium mt-2.5">咨询客服</div>
            </div>
          </div>
        </div>
      </div>
      <!--mobile-->
      <div class="container mx-auto px-3 md:hidden block">
        <div class="flex justify-between items-center border-b border-[#3A3D43] py-5">
          <div class="flex items-center" v-show="config.ContactPhone">
            <img src="@/assets/hot_call.png" alt="" class="h-[30px] w-[30px]">
            <div class="text-[#cccccc]  pl-2">
              <div class="text-sm">联系电话</div>
              <div class="font-bold text-base pt-1">{{ config.ContactPhone.split(',')[0] }}</div>
            </div>
          </div>
          <div class="flex items-center ml-3" v-show="config.HighTechNumber">
            <img src="@/assets/gaoxinjishu.png" alt="" class="h-[30px] w-[30px]">
            <div class="text-[#cccccc]  pl-2">
              <div class="text-sm">国家高新技术企业编号</div>
              <div class="font-bold text-base pt-1">{{ config?.HighTechNumber }}</div>
            </div>
          </div>
        </div>
        <div class="flex items-start justify-between py-5" v-show="navs.length">
          <div class="flex-1 md:text-left text-center" v-for="(item, footerIndex) in navs" :key="footerIndex">
            <div class="text-white leading-6.5 text-base font-medium"
                 @click="toPage(item)">{{ item.name }}
            </div>
            <div class="text-hover cursor-pointer text-[#cccccc] text-sm pt-4 leading-6"
                 v-for="(child,index) in item.children" :key="index"
                 @click="toPage(child)">
              {{ child.name }}
            </div>
          </div>
        </div>
        <div class="text-center pb-5" v-show="config.ContactQrcode">
          <img :src="config.ContactQrcode" alt="" class="inline lg:w-[146px] lg:h-[146px]  w-[100px] h-[100px]">
          <div class="text-[#cccccc] text-center text-sm font-medium mt-2.5">咨询客服</div>
        </div>
      </div>
    </div>
    <div class="bg-[#1E1D1D] text-center text-sm text-[#cccccc] md:p-6 py-3 px-2.5 leading-[22px]" v-if="config">
      {{ config?.AppName || '' }} 版权所有
      <a class="text-hover md:inline hidden" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
        {{ config?.BeiAn || '' }}
      </a>
      <div class="block md:hidden">
        <a class="text-hover" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
          {{ config?.BeiAn || '' }}</a>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    config: {
      type: Object,
      default() {
        return null
      }
    },
    navs: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  mounted() {

  },
  methods: {
    toPage(item) {
      if (!item || !item.link) return;
      if (item.link == this.$route.fullPath) {
        window.location.reload()
        return
      }
      this.$router.push(item.link)
    }
  },
}

</script>
<style scoped>
.text-hover {
  transition: all .5s;
}

.text-hover:hover {
  color: #007AFF;
}
</style>