<template>
  <div>
    <PageHeader :config="config" :navs="header_navs"></PageHeader>
    <router-view></router-view>
    <PageFooter :config="config" :navs="footer_navs"></PageFooter>
  </div>
</template>

<script>
import PageFooter from './components/pageFooter.vue'
import PageHeader from './components/pageHeader.vue'
import {defaultSystemConfig, webNavs} from "@/api/api";

export default {
  name: 'PageLayouts',
  components: {
    PageFooter,
    PageHeader,
  },
  data() {
    return {
      config: null,
      header_navs: [],
      footer_navs: [],
    }
  },
  watch: {
    '$route': function (to) {
      if (to.meta.title) {
        document.title = to.meta.title + '-' + this.config.AppName
      } else {
        document.title = this.config.AppName
      }
    }
  },
  mounted() {
    this.getConfig()
    this.getNavs()
  },
  methods: {
    getConfig() {
      defaultSystemConfig().then(res => {
        localStorage.setItem('LDYCONFIG', JSON.stringify(res))
        this.$nextTick(() => {
          this.config = res
        })
      })
    },
    getNavs() {
      webNavs().then(res => {
        this.$nextTick(() => {
          this.header_navs = res?.header_navs?.children || []
          this.footer_navs = res?.footer_navs?.children || []
        })
      })

    },
  },
}
</script>

