<template>
  <div>
    <div class="index-header md:px-2.5"
         :class="scrollTop < 80 ? 'bg-transparent text-white': 'bg-white text-black'"
         :style="scrollTop < 80 ? '' : 'box-shadow: 0px 1px 8px 0px rgba(0,0,0,0.08)'">
      <!--pc-->
      <div class="container header-container mx-auto hidden md:block">
        <div class="flex justify-between items-center h-[80px]">
          <div class="text-center py-4 logo-wrap" :style="'--primary-opacity:' + (Math.min(80, scrollTop) / 80) + ';'"
               @click="goPage('/home')">
            <img :src="config?.AppLogoWhite" alt="" class="logo white object-contain md:w-[280px] xxl:w-[359px]">
            <img :src="config?.AppLogo" alt="" class="logo primary object-contain md:w-[280px] xxl:w-[359px]">
          </div>
          <div class="flex-1 flex justify-end items-center text-base h-[48px]" v-show="navs.length">
            <div class="relative xxl:px-[50px] lg:px-[20px] px-5 group h-[48px]" v-for="(item,index) in navs"
                 :key="index">
              <div @click="goPage(item.link)"
                   class="cursor-pointer box-border h-full leading-[48px] border-[#007AFF] select-none text-base
                 group-hover:border-b-2">
                {{ item.name }}
              </div>
              <div v-if="item.children && item.children.length >0"
                   class="absolute left-0 top-[78px] z-20 text-center w-full bg-white border box-border text-base
                 invisible group-hover:visible hover:visible">
                <div v-for="(child,index1) in item.children" :key="index1"
                     @click="goPage(child.link)" class="p-3 cursor-pointer select-none"
                     :class="index1 > 0 ? 'border-t':''">
                  {{ child.name }}
                </div>
              </div>
            </div>
            <div class="relative xxl:pl-[105px] pr-[50px] xl:px-[50px] md:px-[20px] group h-[48px]">
              <div
                  class="flex items-center cursor-pointer box-border h-full leading-[48px] border-[#007AFF] select-none">
                <icon-base width="24" height="24" :class="scrollTop < 80 ? 'text-white' : 'text-[#0D6CE4]'">
                  <icon-phone></icon-phone>
                </icon-base>&nbsp;&nbsp;{{config?.ContactPhone.split(',')[0]}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--mobile-->
      <div class="md:hidden px-3 flex justify-between items-center h-[70px] bg-white">
        <div class="text-center max-w-[70%] h-[80%]">
          <img :src="config?.AppLogo" alt="" class="w-full h-full object-contain" @click="goPage('/home')">
        </div>
        <div class="menu w-7 h-7" @click="mobileMenu">
          <i class="w-7 h-0.5"></i>
          <i class="w-7 h-0.5"></i>
          <i class="w-7 h-0.5"></i>
        </div>
      </div>
    </div>
    <el-drawer class="md:hidden" title="" :visible.sync="drawer" direction="rtl">
      <div>
        <div class="text-center px-4">
          <img :src="config?.AppLogo" alt="" class="h-[48px] w-[359px] object-contain"
               @click="goPage('/home')">
        </div>
        <div class="mobile-menu" style="overflow-y: scroll;padding: 0 10px;">
          <div v-for="(item,index) in navs" :key="index"
               class="cursor-pointer box-border h-full leading-[64px] select-none"
               :class="curPath && curPath.includes(getPath(item.link)) ?'text-[#007AFF]': ''">
            <div @click="goPage(item.link)" class="text-center">{{ item.name }}</div>
            <div v-for="(child,indexChild) in item.children" :key="indexChild"
                 @click="goPage(child.link)" class="p-3 cursor-pointer hover:text-[#007AFF] select-none"
                 :class="[curPath && curPath.includes(getPath(item.link)) ?'text-[#007AFF]': '',indexChild > 0 ? 'border-t':'']">
              {{ child.name }}
            </div>
          </div>
        </div>

      </div>
    </el-drawer>
  </div>
</template>
<script>
import IconBase from "@/components/icons/IconBase.vue"
import IconPhone from "@/components/icons/IconPhone.vue"

export default {
  components: {
    IconBase,
    IconPhone,
  },
  props: {
    config: {
      type: Object,
      default() {
        return null;
      }
    },
    navs: {
      type: Array,
      default() {
        return []
      }
    },
  },
  data() {
    return {
      scrollTop: 0,
      curPath: null,
      drawer: false,
      screenWidth: 0,
    }
  },
  watch: {
    $route(to) {
      this.curPath = to.path
      // 滚动到顶部
      window.scrollTo(0, 0);
    }
  },
  mounted() {
    this.curPath = this.$route.path
    // 滚动事件
    window.addEventListener('scroll', () => {
      this.scrollTop = document.documentElement.scrollTop || document.body.scrollTop
    })
    this.screenWidth = window.innerWidth
    window.onresize = () => {
      this.screenWidth = window.innerWidth
    }
  },
  methods: {
    mobileMenu() {
      this.drawer = !this.drawer
    },
    goPage(path) {
      if (!path) return
      if (path == this.$route.fullPath) {
        window.location.reload()
        return
      }
      this.curPath = path
      this.$router.push(path)
      this.drawer = false
    },
    getPath(link) {
      return link.split("?")[0]
    }
  },
}
</script>

<style scoped>
@media (min-width: 1200px) {
  .header-container {
    max-width: 1200px;
  }
}

@media (min-width: 1400px) {
  .header-container {
    max-width: 1400px;
  }
}

@media (min-width: 1600px) {
  .header-container {
    max-width: 1600px;
  }
}

@media (min-width: 1800px) {
  .header-container {
    max-width: 1800px;
  }
}

/deep/ .el-drawer {
  width: 80% !important;
}

.mobile-menu::-webkit-scrollbar {
  display: none;
}

.index-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  box-sizing: border-box;
}

.menu {
  position: relative;
}

.menu i {
  background: #333;
  border-radius: 1px;
}

.menu i:nth-child(1) {
  position: absolute;
  left: 0px;
  top: 0px;
}

.menu i:nth-child(2) {
  position: absolute;
  left: 0px;
  top: 10px;
}

.menu i:nth-child(3) {
  position: absolute;
  left: 0px;
  top: 20px;
}
</style>

<style lang="scss" scoped>
.logo-wrap {
  position: relative;
  height: 48px;
  //max-width: 359px;

  .logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 48px;
    max-width: 359px;

    &.white {
      opacity: calc(1 - var(--primary-opacity));
    }

    &.primary {
      opacity: var(--primary-opacity);
    }
  }
}
</style>