import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from "@/layouts/Index.vue"
// import view_routes from '@/views/routes'
Vue.use(VueRouter)


const routes = [
    {
        path: '/404',
        name: '404',
        component: () => import('@/views/exception/Exception404.vue'),
    },
    {
        path: '/',
        name: 'Layout',
        redirect: '/home',
        component: Layout,
        meta: {title: '首页'},
        children: [
            {
                path: '/home',
                name: 'HomePage',
                component: () => import('@/views/home/index.vue'),
                meta: {title: '首页'}
            },
            {
                path: '/cases',
                name: 'casesPage',
                component: () => import('@/views/cases/index.vue'),
                meta: {title: '产品案例'}
            },
            {
                path: '/cases/:id',
                name: 'casesPage',
                component: () => import('@/views/cases/caseDetail.vue'),
                meta: {title: '产品案例'}
            },
            {
                path: '/news',
                name: 'industryInformationPage',
                component: () => import('@/views/news/index.vue'),
                meta: {title: '行业资讯'}
            },
            {
                path: '/news/:id',
                name: 'NewsDetailPage',
                component: () => import('@/views/news/newDetail.vue'),
                meta: {title: '资讯详情'}
            },
            {
                path: '/aboutUs',
                name: 'aboutUsPage',
                component: () => import('@/views/aboutUs/aboutUs.vue'),
                meta: {title: '关于我们'}
            },
            {
                path: '/contactUs',
                name: 'contactUsPage',
                component: () => import('@/views/contactUs/index.vue'),
                meta: {title: '联系我们'}
            },
            {
                path: '/solution',
                name: 'solutionPage',
                component: () => import('@/views/solution/index.vue'),
                meta: {title: '解决方案'}
            }
        ]
    },
    {
        path: '/:pathMatch(.*)',
        name: 'NotFound',
        redirect: '/404'
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: () => {
        return {x: 0, y: 0}
    }
})

export default router
